<template>
  <div class="login-layout">
    <div class="logo">
      <van-image
        class="logoimg"
        width="30vw"
        height="30vw"
        round
        fit="contain"
        lazy-load
        :src="require('@/assets/images/logo.png')"
      />
    </div>
    <van-form class="login-form" @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="curPerfix"
          is-link
          readonly
          left-icon="aog iconfont icon-ditu"
          @click="showPrefix = true"
        />
        <van-popup v-model:show="showPrefix" round position="bottom">
          <van-picker
            :columns="mobileperfix"
            v-model="selectedlang"
            @cancel="showPrefix = false"
            @confirm="onConfirm"
            :title="PAGELANG.请选择地区"
			:columns-field-names="{ text: 'countryName' ,value:'countryNum' }"
			:rules="rules.username"
			defaultIndex=2
          />
        </van-popup>

        <van-field
          v-model="username"
          :placeholder="PAGELANG.username"
          left-icon="aog iconfont icon-mobile"
          :rules="rules.username"
        />
        <van-field
	      v-if="isYan==0"
          v-model="password"
          type="password"
          :placeholder="PAGELANG.password"
          left-icon="aog iconfont icon-password"
          :rules="rules.password"
        />
		<van-field
		   v-if="isYan==1"
		   v-model="password"
		   center
		   :placeholder="PAGELANG.yanzheng"
		   left-icon="aog iconfont icon-password"
		   :rules="rules.password"
		>
		<template #right-icon>
		       <van-button  size="small"  type="primary" @click="sms()"  :text="codeTime"></van-button>
		</template>
		</van-field>
		
		
      </van-cell-group>
      <!--<div class="forget">
        <a href="#">{{ PAGELANG.forget }}</a>
      </div>-->

      <van-button
        v-if="islogin"
        color="var(--van-card-price-color)"
        block
        loading
        type="primary"
        :loading-text="PAGELANG.in_login"
        size="large"
        class="btn-login"
      />
      <van-button
        v-else
        color="var(--van-card-price-color)"
        block
        type="primary"
        native-type="submit"
        size="large"
        class="btn-login"
      >
        {{ PAGELANG.login_btn }}
      </van-button>
      <div class="register">
        {{ PAGELANG.not_registered }}
        <a href="#/register" style="margin-right:40px;">{{ PAGELANG.register }}</a>
		<a href="#/resetPassword">忘記密碼</a>
		
      </div>
	  
	  

	  
	  <div v-if="isYan==0" @click="isYan=1" style="display:flex;justify-content:center; align-items: center;flex-direction: column;margin-top:20px;">
		  <div style="margin-bottom:5px">
		  		 <van-image
		  		   class="logoimg"
		  		   width="8vw"
		  		   height="8vw"
		  		   round
		  		   fit="contain"
		  		   lazy-load
		  		   :src="require('@/assets/images/icon-code.png')"
		  		 />
		  </div>
		  <div style="font-size:12px;">驗證碼登錄</div>
	  </div>
	  
	  
	  
	  <div v-if="isYan==1" @click="isYan=0" style="display:flex;justify-content:center; align-items: center;flex-direction: column;margin-top:20px;">
	  		  <div style="margin-bottom:5px">
	  		  		 <van-image
	  		  		   class="logoimg"
	  		  		   width="8vw"
	  		  		   height="8vw"
	  		  		   round
	  		  		   fit="contain"
	  		  		   lazy-load
	  		  		   :src="require('@/assets/images/icon-code.png')"
	  		  		 />
	  		  </div>
	  		  <div style="font-size:12px;">密碼登錄</div>
	  </div>
	  
	  
    </van-form>
  </div>


</template>

<style>
.van-icon-aog {
  color: #cfaf6d;
}
</style>

<style scoped>
@media (max-width: 768px) {
  .login-layout-pc {
    display: none;
  }
  .login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .login-layout .logo {
    position: relative;
    border-radius: 50%;
  }

  .login-layout .logo::before,
  .login-layout .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .login-layout .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .login-layout .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .login-layout .logo .logoimg {
    z-index: 100;
  }

  .login-form {
    width: 90vw;
    margin-top: 9vw;
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
  }

  .forget {
    line-height: 38px;
    font-size: 12px;
    text-align: right;
  }

  .forget > a {
    color: #143949;
  }

  .btn-login {
    margin-top: 40px;
  }

  .register {
    text-align: center;
    padding-top: 20px;
    line-height: 44px;
    font-size: 12px;
    color: #979797;
  }

  .register > a {
    font-size: 14px;
    color: #143949;
    margin-left: 10px;
  }
}
</style>
<style src="../assets/css/login.css" scoped></style>

<script>
	
import md5 from 'md5'	
import { getCurrentInstance } from "vue";
import { closeToast, showToast, showLoadingToast } from "vant";
import { getCookie, setCookie } from "../util";
export default {
  data() {
    const _this = getCurrentInstance();
    const gp = _this.appContext.config.globalProperties;

    return {
      PAGELAN: {},
      mobileperfix: [],
      curPerfix: "中國台灣",
	  codeTime:"獲取驗證碼",
      selectedlang: [""],
      username: "",
      password: "",
      showPrefix: false,
      islogin: false,
      globalprops: gp,
      logininfo: { username: "", password: "" },
      rules: {
        username: [
          { required: true, message: this.PAGELANG.username, trigger: "blur" },
        ],
        password: [
          { required: true, message: this.PAGELANG.password, trigger: "blur" },
        ],
      },
	  areaPhone:"(886)",
	  isYan:0,
	  time:60
    };
  },
  beforeCreate() {
    console.log(this);
    this.PAGELANG = this.LANG.pages.login;
    this.SYSLANG = this.LANG.system;

    this.langcode = getCookie("lh_aog_langcode");
  },
  methods: {
	  
	fun() {
		console.log('12312312')
		console.log(this.time)
		this.time=this.time-1;
		if (this.time >= 0) {
			this.codeTime =this.time+ "s重新发送";
			this.$forceUpdate();
		} else if (this.time < 0) {
			
			this.codeTime = "重新发送";
			this.disabledSms = false; //倒计时结束能够重新点击发送的按钮 
			clearInterval(this.set_interval); //清除定时器 
			this.time = 60; //设置循环重新开始条件 
			this.$forceUpdate();
		}
		return true
	},  
	//發送短信驗證碼獲取token
	sms(){
		if(this.time!=60){
		  return;	
		}
		this.set_interval=setInterval(()=>{
			this.fun();
		},1000)
		this.axios.get(this.actions.getSmsToken,{params:{
	      // cateid: this.cateid * 1,
		  phone:this.areaPhone+this.username,
	      platformType:1,
	    }}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") { 
				console.log(response)
			    this.getSms(response.data.data.token)
			}
			
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},
	getSms(token){
		this.axios.get(this.actions.getSms,{params:{
		  // cateid: this.cateid * 1,
		  phone:this.areaPhone+this.username,
		  platformType:1,
		  token:token
		}}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") {
				showToast({
				  message:message,
				});
				// console.log(response)
				// this.disabledSms=true
			    // this.set_interval=setInterval(()=>{
				// 	this.fun()
				// },1000)
	
			}
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},  
	  
	  
    onConfirm({ selectedOptions }) {
      console.log('selectedOptions',selectedOptions);
      this.showPrefix = false;
      this.curPerfix = selectedOptions[0].countryName;
	  this.areaPhone=selectedOptions[0].countryNum;
      //setCookie("lh_aog_langcode", selectedOptions[0].value);
      //location.reload();
    },

    onChangeLang(val) {
      this.langcode = val;
      setCookie("lh_aog_langcode", val);
      location.reload();
    },

    onLogin() {
      this.$refs.refLogin.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.username = this.areaPhone+this.logininfo.username;
          this.password = this.logininfo.password;
          this.onSubmit();
        } else {
          return false;
        }
      });
    },

    onSubmit() {
      let postobj = {};

      postobj.username = this.areaPhone+this.username;
      postobj.platformType=1
	  postobj.deviceType=4
	  
	  if(this.isYan==0){
		postobj.type=1 //1是密碼登錄 2是驗證碼  
		postobj.password =md5(this.password)
	  }else{
		postobj.type=2 //1是密碼登錄 2是驗證碼  
		postobj.password =this.password
	  }
	  
	  
      console.log(postobj);

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.islogin = true;
      
	  
	  
	  
      this.axios.post(this.actions.user_login,postobj).then((response) => {
        this.islogin = false;
        //登录结果
        let retdata = response.data;
        console.log('retdata',retdata);
        closeToast();

        if (response.data.code == "200") {
          //保存好agentid和logintoken
		  console.log("登錄成功")
          //setCookie("agentloginid", retdata.agentid, 30);
          setCookie("agentlogintoken", retdata.data.token, 30);
		  //开始赚钱 更新保证金状态
		 
		  
		  //直接进入首页
          this.$router.push("/")
		  
          // if (retdata.status == 1) {
          //   //直接进入首页
          //   this.$router.push("/");
          // } else if (
          //   retdata.status == 0 &&
          //   retdata.curstep > 0 &&
          //   retdata.curstep < 7
          // ) {
          //   this.$router.push("/appflow");
          // }
        } else {
          showToast({
			  message:response.data.message
		  });
        }
      });
    },

    onKeyDown(e) {
      if (e.keyCode == 13) {
        this.onLogin();
      }
    },
  },

  created() {
    this.axios.get(this.actions.area_prefix).then((response) => {
      console.log(response, this.langcode);
      this.mobileperfix = response.data.data;
      let langitem = this.mobileperfix.find((item) => {
        if (item.value == this.langcode) return true;
        return false;
      });

      if (langitem) {
        this.selectedlang = [langitem.value];
        this.curPerfix = langitem.text;
      }

      // if(langitem)
      //   this.selectedlang =
      // // this.curPerfix = response.data[0].text;
    });
  },
};
</script>
